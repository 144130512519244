import { navigate } from "gatsby"
import React from "react"
import Video from "../components/Video/Video"
import useClickDetector from "../utils/useClickDetector"
import HeaderSEO from "../components/Header/HeaderSEO"

const ForewordPage = () => {
  useClickDetector()

  return (
    <div className={`visiting-room-wrap container`}>
      <Video
        name={"Introduction"}
        onClose={() => {
          window.localStorage.setItem("hasClick", "true")
          navigate("/visiting-room?skip=1")
        }}
        hasCaptions={false}
        video_link="https://vimeo.com/447172431"
      />
    </div>
  )
}

export const Head = () => <HeaderSEO />

export default ForewordPage
